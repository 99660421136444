import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BuildIcon from "@mui/icons-material/Build";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import {Home} from "@mui/icons-material";

const FleetSideNavigation = ({ sideNavOpen, redirectPage }: any) => {
  return (
    <React.Fragment>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: sideNavOpen ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            redirectPage("/management");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sideNavOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {" "}
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText
            primary="Fleet Management"
            sx={{ display: sideNavOpen ? "block" : "none" }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: sideNavOpen ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            redirectPage("/providers");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sideNavOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {" "}
            <BuildIcon />
          </ListItemIcon>
          <ListItemText
            primary="Service Providers"
            sx={{ display: sideNavOpen ? "block" : "none" }}
          />
        </ListItemButton>
      </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: sideNavOpen ? "initial" : "center",
                    px: 2.5,
                }}
                onClick={() => {
                    redirectPage("/serviceRequests");
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: sideNavOpen ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    {" "}
                    <HomeRepairServiceIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Requests"
                    sx={{ display: sideNavOpen ? "block" : "none" }}
                />
            </ListItemButton>
        </ListItem>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: sideNavOpen ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            redirectPage("/billing/fleet");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sideNavOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {" "}
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText
            primary="Billing"
            sx={{ display: sideNavOpen ? "block" : "none" }}
          />
        </ListItemButton>
      </ListItem>
    </React.Fragment>
  );
};

export default FleetSideNavigation;
