import * as React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useOktaAuth } from "@okta/okta-react";
import { useUserContext } from "../../../context/UserProvider";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminImpersonationSelector from "../../pages/Admin/AdminImpersonationSelector";

export default function UserMenu({ theme }: any) {
  const { oktaAuth } = useOktaAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { currentUser } = useUserContext();

  const logout = async () => {
    await oktaAuth.signOut();
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ ml: 3 }}
      >
        <AccountCircleIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          disabled
          sx={{
            "&.Mui-disabled": {
              opacity: 1,
            },
          }}
        >
          <Typography>
            {`${currentUser?.firstName} ${currentUser?.lastName}`}
          </Typography>
        </MenuItem>

        {currentUser?.originalUserType === "Admin" ? (
          <AdminImpersonationSelector />
        ) : null}

        <Divider />
        <MenuItem onClick={() => logout()}>
          <LogoutIcon sx={{ mr: 1 }} />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
