import React from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useLocation, useParams } from "react-router-dom";
import { RequestDto } from "../../../api/TrailerPMApi.generated";
const PageTitle = () => {
  const location = useLocation();

  let titles = [
    { title: "Fleet Management", pathName: "/management" },
    { title: "Services", pathName: "/services" },
    { title: "Fleet Admin", pathName: "/admin/fleets" },
    { title: "Provider Admin", pathName: "/admin/providers" },
    { title: "Configuration", pathName: "/admin/config" },
    { title: "Service Providers", pathName: "/providers" },
    { title: "Service Requests", pathName: "/requests" },
    { title: "Service Requests", pathName: "/serviceRequests" },
    { title: "Billing", pathName: "/billing/fleet" },
    { title: "Billing", pathName: "/billing/provider" },
  ];

  let obj = titles?.find((o) => o?.pathName === location?.pathname);

  return (
    <Grid>
      <Typography variant="h5">{obj?.title} </Typography>
    </Grid>
  );
};

export default PageTitle;
