import React, { useEffect, useState } from "react";
import { useUserContext } from "../../../context/UserProvider";

import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";

import { styled, lighten, darken } from "@mui/system";
import { useOktaAuth } from "@okta/okta-react";
import {
  GroupableListItemDto,
  ImpersonationApi,
  SetImpersonationDto,
} from "../../../api/TrailerPMApi.generated";
import { useNavigate } from "react-router-dom";

const AdminImpersonationSelector = () => {
  const { currentUser, setCurrentUser } = useUserContext();
  const navigate = useNavigate();

  const { oktaAuth } = useOktaAuth();
  const token = oktaAuth.getAccessToken();
  const impersonationApi = new ImpersonationApi();

  const [value, setValue] = React.useState<GroupableListItemDto | null>(null);
  const [inputValue, setInputValue] = React.useState("");

  const [data, setData] = useState<GroupableListItemDto[]>([]);

  const getData = async () => {
    impersonationApi.token = token;
    return await impersonationApi.getImpersonationList();
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getData();
      setData(data);

      if (currentUser?.userType === "FleetUser") {
        let fleet =
          data.find(
            (o) => o.group === "Fleets" && o.value === currentUser?.fleetId
          ) ?? null;
        setValue(fleet);
      } else if (currentUser?.userType === "ServiceProvider") {
        setValue(
          data.find(
            (o) =>
              o.group === "Providers" &&
              o.value === currentUser?.serviceProviderId
          ) ?? null
        );
      } else {
        const admin = data.find((o) => o.group === "Admin") ?? null;
        setValue(admin);
        setInputValue(admin?.display ?? "");
      }
    };
    fetchData();
  }, []);

  const onImpersonation = (dto: GroupableListItemDto | null) => {
    if (dto !== null) {
      impersonationApi.token = token;
      impersonationApi
        .impersonate(
          new SetImpersonationDto({
            id: dto.group === "Admin" ? null : dto?.value,
            userType: dto.group,
          })
        )
        .then((result) => {
          if (result) {
            setCurrentUser(result);
            navigate("/");
          }
        });
    }
  };

  return (
    <Autocomplete
      id="impersonation-select"
      value={value}
      onChange={(event: any, newValue: GroupableListItemDto | null) => {
        setValue(newValue);
        onImpersonation(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={data}
      getOptionLabel={(option) => option.display ?? ""}
      renderInput={(params) => <TextField {...params} label="Impersonation" />}
      groupBy={(option) => option.group ?? ""}
      sx={{ minWidth: 200, m: 1 }}
    />
  );
};

export default AdminImpersonationSelector;
