// LoadingContext.js
import { createContext, useContext, useState } from "react";

const GlobalLoadingContext = createContext({
  globalLoading: false,
  setGlobalLoading: (val: boolean) => {},
  loadingData: false,
  setLoadingData: (val: boolean) => {},
});

export function LoadingProvider({ children }: any) {
  const [globalLoading, setGlobalLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  return (
    <GlobalLoadingContext.Provider
      value={{ globalLoading, setGlobalLoading, loadingData, setLoadingData }}
    >
      {children}
    </GlobalLoadingContext.Provider>
  );
}

export function useGlobalLoading() {
  const context = useContext(GlobalLoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}
