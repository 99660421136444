import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BuildIcon from "@mui/icons-material/Build";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";

const ProviderSideNavigation = ({ sideNavOpen, redirectPage }: any) => {
  return (
    <React.Fragment>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: sideNavOpen ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            redirectPage("/services");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sideNavOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {" "}
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            primary="Services"
            sx={{ display: sideNavOpen ? "block" : "none" }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: sideNavOpen ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            redirectPage("/requests");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sideNavOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {" "}
            <EmailIcon />
          </ListItemIcon>
          <ListItemText
            primary="Requests"
            sx={{ display: sideNavOpen ? "block" : "none" }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: sideNavOpen ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            redirectPage("/billing/provider");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sideNavOpen ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {" "}
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText
            primary="Billing"
            sx={{ display: sideNavOpen ? "block" : "none" }}
          />
        </ListItemButton>
      </ListItem>
    </React.Fragment>
  );
};

export default ProviderSideNavigation;
