import * as React from "react";
import SideNav from "./SideNav";
import Box from "@mui/material/Box";
import { Backdrop, CircularProgress, Grid, Paper } from "@mui/material";
import PageTitle from "./PageTitle";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import { useGlobalLoading } from "../../../context/LoadingContext";

const Layout = ({ theme, children }: any) => {
  const { globalLoading } = useGlobalLoading();

  return (
    <Box sx={{ display: "flex" }}>
      <SideNav theme={theme} children={children}></SideNav>
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Grid container>
          <Grid item xs={12}>
            <PageTitle />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={globalLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Box>
  );
};

export default Layout;
